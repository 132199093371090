import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1><a parentName="h1" {...{
        "href": "#markdown"
      }}>{`markdown`}</a></h1>
    <p>{`gatsby-theme-terminal supports the full set of
`}<a parentName="p" {...{
        "href": "https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
      }}>{`markdown shortcodes`}</a></p>
    <hr />
    <h2><a parentName="h2" {...{
        "href": "#heading"
      }}>{`Heading`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-markdown"
      }}>{`# h1

## h2

### h3

#### h4

##### h5

###### h6

Alternatively, for h1 and h2, an underline-ish style:

# Alt-h1

Alt-h2
`}</code></pre>
    <h1>{`h1`}</h1>
    <h2>{`h2`}</h2>
    <h3>{`h3`}</h3>
    <h4>{`h4`}</h4>
    <h5>{`h5`}</h5>
    <h6>{`h6`}</h6>
    <p>{`Alternatively, for h1 and h2, an underline-ish style:`}</p>
    <h2><a parentName="h2" {...{
        "href": "#alt-h1"
      }}>{`Alt-h1`}</a></h2>
    <p>{`Alt-h2`}</p>
    <h2><a parentName="h2" {...{
        "href": "#emphasis"
      }}>{`Emphasis`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-markdown"
      }}>{`Emphasis, aka italics, with _asterisks_ or _underscores_.

Strong emphasis, aka bold, with **asterisks** or **underscores**.

Combined emphasis with **asterisks and _underscores_**.

Strikethrough uses two tildes. ~~Scratch this.~~
`}</code></pre>
    <p>{`Emphasis, aka italics, with `}<em parentName="p">{`asterisks`}</em>{` or `}<em parentName="p">{`underscores`}</em>{`.`}</p>
    <p>{`Strong emphasis, aka bold, with `}<strong parentName="p">{`asterisks`}</strong>{` or `}<strong parentName="p">{`underscores`}</strong>{`.`}</p>
    <p>{`Combined emphasis with `}<strong parentName="p">{`asterisks and `}<em parentName="strong">{`underscores`}</em></strong>{`.`}</p>
    <p>{`Strikethrough uses two tildes. `}<del parentName="p">{`Scratch this.`}</del></p>
    <hr />
    <h2><a parentName="h2" {...{
        "href": "#lists"
      }}>{`Lists`}</a></h2>
    <p>{`(In this example, leading and trailing spaces are shown with with dots: ⋅)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-markdown"
      }}>{`1. First ordered list item
2. Another item
3. Actual numbers don't matter, just that it's a number
4. And another item.
5. Code in list \`boop\`

- Unordered list can use asterisks

* Or minuses

- Or pluses
`}</code></pre>
    <ol>
      <li parentName="ol">{`First ordered list item`}</li>
      <li parentName="ol">{`Another item`}</li>
      <li parentName="ol">{`Actual numbers don't matter, just that it's a number`}</li>
      <li parentName="ol">{`And another item.`}</li>
      <li parentName="ol">{`Code in list `}<inlineCode parentName="li">{`boop`}</inlineCode></li>
    </ol>
    <ul>
      <li parentName="ul">{`Unordered list can use asterisks`}</li>
    </ul>
    <ul>
      <li parentName="ul">{`Or minuses`}</li>
    </ul>
    <ul>
      <li parentName="ul">{`Or pluses`}</li>
    </ul>
    <hr />
    <h2><a parentName="h2" {...{
        "href": "#links"
      }}>{`Links`}</a></h2>
    <p>{`There are two ways to create links.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-markdown"
      }}>{`[I'm an inline-style link](https://www.google.com)

[I'm an inline-style link with title](https://www.google.com "Google's Homepage")

[I'm a reference-style link][arbitrary case-insensitive reference text]

[I'm a relative reference to a repository file](../blob/master/LICENSE)

[You can use numbers for reference-style link definitions][1]

Or leave it empty and use the [link text itself].

URLs and URLs in angle brackets will automatically get turned into links. http://www.example.com or
<http://www.example.com> and sometimes example.com (but not on Github, for example).

Some text to show that the reference links can follow later.

[arbitrary case-insensitive reference text]: https://www.mozilla.org
[1]: http://slashdot.org
[link text itself]: http://www.reddit.com
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://www.google.com"
      }}>{`I'm an inline-style link`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.google.com",
        "title": "Google's Homepage"
      }}>{`I'm an inline-style link with title`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.mozilla.org"
      }}>{`I'm a reference-style link`}</a></p>
    <p><a parentName="p" {...{
        "href": "../blob/master/LICENSE"
      }}>{`I'm a relative reference to a repository file`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://slashdot.org"
      }}>{`You can use numbers for reference-style link definitions`}</a></p>
    <p>{`Or leave it empty and use the `}<a parentName="p" {...{
        "href": "http://www.reddit.com"
      }}>{`link text itself`}</a>{`.`}</p>
    <p>{`URLs and URLs in angle brackets will automatically get turned into links. `}<a parentName="p" {...{
        "href": "http://www.example.com"
      }}>{`http://www.example.com`}</a>{` or
`}<a parentName="p" {...{
        "href": "http://www.example.com"
      }}>{`http://www.example.com`}</a>{` and sometimes example.com (but not on Github, for example).`}</p>
    <p>{`Some text to show that the reference links can follow later.`}</p>
    <h2><a parentName="h2" {...{
        "href": "#images"
      }}>{`Images`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-markdown"
      }}>{`Here's our logo (hover to see the title text):

Inline-style:
![alt text](https://github.com/adam-p/markdown-here/raw/master/src/common/images/icon48.png 'Logo Title Text 1')

Reference-style: ![alt text][logo]

[logo]: https://github.com/adam-p/markdown-here/raw/master/src/common/images/icon48.png 'Logo Title Text 2'
`}</code></pre>
    <p>{`Here's our logo (hover to see the title text):`}</p>
    <p>{`Inline-style:
`}<img parentName="p" {...{
        "src": "https://github.com/adam-p/markdown-here/raw/master/src/common/images/icon48.png",
        "alt": "alt text",
        "title": "Logo Title Text 1"
      }}></img></p>
    <p>{`Reference-style: `}<img parentName="p" {...{
        "src": "https://github.com/adam-p/markdown-here/raw/master/src/common/images/icon48.png",
        "alt": "alt text",
        "title": "Logo Title Text 2"
      }}></img></p>
    <h2><a parentName="h2" {...{
        "href": "#code-and-syntax-highlighting"
      }}>{`Code and Syntax Highlighting`}</a></h2>
    <p>{`Code blocks are part of the Markdown spec, but syntax highlighting isn't.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-markdown"
      }}>{`Inline \`code\` has \`back-ticks around\` it.
`}</code></pre>
    <p>{`Inline `}<inlineCode parentName="p">{`code`}</inlineCode>{` has `}<inlineCode parentName="p">{`back-ticks around`}</inlineCode>{` it.`}</p>
    <p>{`Blocks of code are either fenced by lines with three back-ticks \`\`\`, or are indented with four spaces. I recommend only
using the fenced code blocks -- they're easier and only they support syntax highlighting, but you must provide a
language or none`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-markdown"
      }}>{`\`\`\`javascript
var s = 'JavaScript syntax highlighting'
alert(s)
\`\`\`

\`\`\`python
s = "Python syntax highlighting"
print s
\`\`\`

\`\`\`html
<div>HTML syntax highlighting</div>
\`\`\`

\`\`\`none
var s = "JavaScript syntax highlighting"
alert(s)
\`\`\`
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`var s = 'JavaScript syntax highlighting'
alert(s)
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`s = "Python syntax highlighting"
print s
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div>HTML syntax highlighting</div>
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-none"
      }}>{`var s = "JavaScript syntax highlighting"
alert(s)
`}</code></pre>
    <h2><a parentName="h2" {...{
        "href": "#tables"
      }}>{`Tables`}</a></h2>
    <p>{`Tables aren't part of the core Markdown spec, but they are part of GFM and Markdown Here supports them. They are an easy
way of adding tables to your email -- a task that would otherwise require copy-pasting from another application.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-markdown"
      }}>{`Colons can be used to align columns.

| Tables        |      Are      |   Cool |
| ------------- | :-----------: | -----: |
| col 3 is      | right-aligned | \\$1600 |
| col 2 is      |   centered    |   \\$12 |
| zebra stripes |   are neat    |    \\$1 |

There must be at least 3 dashes separating each header cell. The outer pipes (|) are optional, and you don't need to
make the raw Markdown line up prettily. You can also use inline Markdown.

| Markdown | Less      | Pretty     |
| -------- | --------- | ---------- |
| _Still_  | \`renders\` | **nicely** |
| 1        | 2         | 3          |
`}</code></pre>
    <p>{`Colons can be used to align columns.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Tables`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Are`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Cool`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`col 3 is`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`right-aligned`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$1600`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`col 2 is`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`centered`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$12`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`zebra stripes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`are neat`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$1`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`There must be at least 3 dashes separating each header cell. The outer pipes (|) are optional, and you don't need to
make the raw Markdown line up prettily. You can also use inline Markdown.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Markdown`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Less`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Pretty`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Still`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`renders`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`nicely`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
        </tr>
      </tbody>
    </table>
    <hr />
    <h2><a parentName="h2" {...{
        "href": "#blockquotes"
      }}>{`Blockquotes`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-markdown"
      }}>{`> Blockquotes are very handy in email to emulate reply text. This line is part of the same quote.

Quote break.

> This is a very long line that will still be quoted properly when it wraps. Oh boy let's keep writing to make sure this
> is long enough to actually wrap for everyone. Oh, you can _put_ **Markdown** into a blockquote.
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`Blockquotes are very handy in email to emulate reply text. This line is part of the same quote.`}</p>
    </blockquote>
    <p>{`Quote break.`}</p>
    <blockquote>
      <p parentName="blockquote">{`This is a very long line that will still be quoted properly when it wraps. Oh boy let's keep writing to make sure this
is long enough to actually wrap for everyone. Oh, you can `}<em parentName="p">{`put`}</em>{` `}<strong parentName="p">{`Markdown`}</strong>{` into a blockquote.`}</p>
    </blockquote>
    <h2><a parentName="h2" {...{
        "href": "#inline-html"
      }}>{`Inline HTML`}</a></h2>
    <p>{`You can also use raw HTML in your Markdown, and it'll mostly work pretty well.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-markdown"
      }}>{`<dl>
  <dt>Definition list</dt>
  <dd>Is something people use sometimes.</dd>

  <dt>Markdown in HTML</dt>
  <dd>Does *not* work **very** well. Use HTML <em>tags</em>.</dd>
</dl>
`}</code></pre>
    <dl>
  <dt>Definition list</dt>
  <dd>Is something people use sometimes.</dd>
  <dt>Markdown in HTML</dt>
  <dd>Does *not* work **very** well. Use HTML <em>tags</em>.</dd>
    </dl>
    <hr />
    <h2><a parentName="h2" {...{
        "href": "#horizontal-rule"
      }}>{`Horizontal Rule`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-markdown"
      }}>{`Three or more...

---

Hyphens

---

Asterisks

---

Underscores
`}</code></pre>
    <p>{`Three or more...`}</p>
    <hr></hr>
    <p>{`Hyphens`}</p>
    <hr></hr>
    <p>{`Asterisks`}</p>
    <hr></hr>
    <p>{`Underscores`}</p>
    <h2><a parentName="h2" {...{
        "href": "#line-breaks"
      }}>{`Line Breaks`}</a></h2>
    <p>{`My basic recommendation for learning how line breaks work is to experiment and discover -- hit `}<inlineCode parentName="p">{`<Enter>`}</inlineCode>{` once (i.e.,
insert one newline), then hit it twice (i.e., insert two new lines), see what happens. You'll soon learn to get what you
want. "Markdown Toggle" is your friend.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-markdown"
      }}>{`Here's a line for us to start with.

This line is separated from the one above by two newlines, so it will be a _separate paragraph_.

This line is also a separate paragraph, but... This line is only separated by a single newline, so it's a separate line
in the _same paragraph_.
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      